<lockbin-progress-bar [isInComponentList]="true" *ngIf="(storeService.isLoading$ | async)"></lockbin-progress-bar>

<lockbin-layout-content>

  <div class="nk-block-head nk-block-head-sm">
      <div class="nk-block-between">
          <div class="nk-block-head-content">
              <h3 class="nk-block-title page-title">Listado de usuarios</h3>
              <div class="nk-block-des text-soft">
                  <p>Mostrando<span *ngIf="dataItems.length>0"> {{ dataItems.length }} de</span> un total de {{ totalItems | number }} usuarios<span *ngIf="searchString.length > 0"> para la búsqueda por "<strong>{{ searchString }}</strong>"</span>.</p>
              </div>
          </div>
          <div class="nk-block-head-content">
              <div class="toggle-wrap nk-block-tools-toggle">
                  <a href="#" class="btn btn-icon btn-trigger toggle-expand me-n1" data-target="pageMenu"><em class="icon ni ni-menu-alt-r"></em></a>
                  <div class="toggle-expand-content" data-content="pageMenu">
                      <ul class="nk-block-tools g-3">
                          <li>
                            <lockbin-btn-export
                              (doClick)="doExportClick($event)"
                              [dataToExport]="this.dataRawItems"
                              [numDataToExport]="this.storeService.getNumDataToExport()">
                            </lockbin-btn-export>
                            <!-- <button (click)="doExport($event)" href="#" class="btn btn-white btn-outline-light">
                              <em class="icon ni ni-download-cloud"></em><span>Exportar</span>
                            </button> -->
                          </li>
                          <li class="nk-block-tools-opt">
                              <div class="drodown" ngbDropdown>
                                  <button href="#" class="dropdown-toggle btn btn-icon btn-primary" data-bs-toggle="dropdown" ngbDropdownToggle><em class="icon ni ni-plus"></em></button>
                                  <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                                      <ul class="link-list-opt no-bdr">
                                          <li><a [routerLink]="[this.routeToAdd]"><span>Nuevo Usuario</span></a></li>
                                      </ul>
                                  </div>
                              </div>
                          </li>
                      </ul>
                  </div>
              </div>
          </div>
      </div>
  </div>

  <div class="nk-block">
    <div class="card card-bordered card-stretch">
        <div class="card-inner-group">

          <lockbin-header-tools
            [bulkOperations]= "dataListWrapper.bulkOperations"
            [useSearch]= "dataListWrapper.useSearch"
            [searchPlaceholder]= "dataListWrapper.searchPlaceholder"
            [dataListHeader]= "dataListWrapper.header"
            [numElementsChecked]="numElementsChecked"
            (doBulkOperation)="doBulkOperation($event)"
            (changePaginationItemsPerPage)="doChangePaginationItemsPerPage($event)"
            (changePaginationSortOrder)="doChangePaginationSortOrder($event)"
            (doSearch)="doSearchByParams($event)"
            class="card-inner position-relative card-tools-toggle d-block">
          </lockbin-header-tools>

          <div class="card-inner p-0">
            <div class="nk-tb-list nk-tb-ulist" *ngIf="(storeService.isLoading$ | async) === false">
                <div class="nk-tb-item nk-tb-head">
                    <div class="nk-tb-col nk-tb-col-check" *ngIf="dataListWrapper.useCheckbox">
                        <div class="custom-control custom-control-sm custom-checkbox notext">
                            <input type="checkbox" (change)="doChangeAllCheckboxesStatus($event)" class="custom-control-input" id="uid">
                            <label class="custom-control-label" for="uid"></label>
                        </div>
                    </div>

                    <div class="nk-tb-col" *ngFor="let currentHeader of dataListWrapper.header.headers" [ngClass]="currentHeader.visibleSize ? 'tb-col-' + currentHeader.visibleSize : ''">
                      <span class="sub-text">{{ currentHeader.title }}</span>
                    </div>

                    <div class="nk-tb-col nk-tb-col-tools text-end" *ngIf="dataListWrapper.header.canToggleItems">
                        <!-- <div class="dropdown" ngbDropdown>
                            <button class="btn btn-xs btn-outline-light btn-icon dropdown-toggle" data-bs-toggle="dropdown" data-offset="0,5" ngbDropdownToggle><em class="icon ni ni-plus"></em></button>
                            <div class="dropdown-menu dropdown-menu-xs dropdown-menu-end" ngbDropdownMenu>
                                <ul class="link-tidy sm no-bdr">
                                    <li>
                                        <div class="custom-control custom-control-sm custom-checkbox">
                                            <input type="checkbox" class="custom-control-input" checked="" id="bl">
                                            <label class="custom-control-label" for="bl">Balance</label>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="custom-control custom-control-sm custom-checkbox">
                                            <input type="checkbox" class="custom-control-input" checked="" id="ph">
                                            <label class="custom-control-label" for="ph">Phone</label>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="custom-control custom-control-sm custom-checkbox">
                                            <input type="checkbox" class="custom-control-input" id="vri">
                                            <label class="custom-control-label" for="vri">Verified</label>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="custom-control custom-control-sm custom-checkbox">
                                            <input type="checkbox" class="custom-control-input" id="st">
                                            <label class="custom-control-label" for="st">Status</label>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div> -->
                    </div>
                </div>

                <div class="nk-tb-item" *ngFor="let dataItem of dataItems">

                  <div class="nk-tb-col nk-tb-col-check" *ngIf="dataListWrapper.useCheckbox">
                      <div class="custom-control custom-control-sm custom-checkbox notext">
                          <input type="checkbox" #rowCheckbox (change)="doChangeRowCheckboxStatus($event)" class="custom-control-input" [id]="'uid' + dataItem.id" value="{{ dataItem.id }}">
                          <label class="custom-control-label" [for]="'uid' + dataItem.id"></label>
                      </div>
                  </div>

                  <div class="nk-tb-col" [ngClass]="getCssClassColumn('login')">
                    <lockbin-cell-basic (doClick)="showModalQuickView(dataItem.content)" [hasAction]="true">
                      {{ dataItem.content.login }}
                    </lockbin-cell-basic>
                  </div>
                  <div class="nk-tb-col" [ngClass]="getCssClassColumn('code')">
                    <lockbin-cell-basic [hasAction]="false">
                      {{ dataItem.content.code }}
                    </lockbin-cell-basic>
                  </div>
                  <div class="nk-tb-col" [ngClass]="getCssClassColumn('name')">
                    <lockbin-cell-basic [hasAction]="false">
                      {{ dataItem.content.name }}
                    </lockbin-cell-basic>
                  </div>
                  <div class="nk-tb-col" [ngClass]="getCssClassColumn('role')">
                    <lockbin-cell-basic [hasAction]="false">
                      <ng-container *ngIf="dataItem.content.roles?.length>0">
                        {{ dataItem.content.roles[0].name }}
                      </ng-container>

                    </lockbin-cell-basic>
                  </div>
                  <div class="nk-tb-col" [ngClass]="getCssClassColumn('customer')">
                    <lockbin-cell-basic [hasAction]="false">
                      {{ dataItem.content.customer.name }}
                    </lockbin-cell-basic>
                  </div>
                  <div class="nk-tb-col" [ngClass]="getCssClassColumn('email')">
                    <lockbin-cell-basic [hasAction]="false">
                      {{ dataItem.content.email }}
                    </lockbin-cell-basic>
                  </div>
                  <div class="nk-tb-col nk-tb-col-tools text-end">
                    <lockbin-row-operations [rowOperations]="dataListWrapper.rowOperations" [item]="dataItem" (doClick)="doRowClick($event)"></lockbin-row-operations>
                  </div>
                </div>


            </div>
          </div>

          <div class="card-inner">

            <div class="nk-block-between-md g-3">
              <lockbin-pagination mode="buttons" [itemsPerPage]="itemsPerPage" [totalItems]="totalItems" [currentPage]="currentPage" (paginationChange)="doChangePagination($event)"></lockbin-pagination>
            </div>

          </div>

        </div>
    </div>
  </div>

  <ng-template #skeleton>
    <!-- <lockbin-progress-bar class="position-fixed w-100"></lockbin-progress-bar> -->
    <span class="placeholder col-6"></span>
    <span class="placeholder w-75"></span>
    <span class="placeholder" style="width: 25%;"></span>
  </ng-template>


<lockbin-modal-wrapper *ngIf="false" [component]="componentModal" [componentData]="componentModalData" [modalData]="modalData"></lockbin-modal-wrapper>

  <!-- <br><br>
  <lockbin-debug [variable]="dataListWrapper"></lockbin-debug> -->

</lockbin-layout-content>
